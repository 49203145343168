.dashboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .dashboard-heading {
    font-weight: 600;
    color: #333;
  }
  
  .custom-table {
    border: none;
    text-align: left;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 15px 20px;
  }
  
  .custom-table thead {
    background-color: #f8f9fa;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .custom-table tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }

  /* Add space between rows */
.custom-table tbody tr {
  margin-bottom: 10px; /* Adds space between rows */
}

/* Optionally, you can also add padding inside the rows to make the content look more spacious */
.custom-table tbody tr td {
  padding: 10px; /* Adjust as needed */
}

  