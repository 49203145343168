.dashboard-header {
  margin-bottom: 20px;
}

.dashboard-heading {
  font-size: 24px;
  font-weight: bold;
}

.enquiry-table {
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

tr:hover {
  background-color: #f1f1f1;
}
