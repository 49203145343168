.dashboard-header {
  margin-bottom: 20px;
  text-align: center;
}

.dashboard-heading {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.enquiry-table {
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
}

.container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #000;
  color: #fff;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.status.booked {
  background-color: #28a745;
}

.status.cancelled {
  background-color: #dc3545;
}

.status.checkin {
  background-color: #007bff;
}

.status.checkout {
  background-color: #6f42c1;
}
.status-dropdown {
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.status-dropdown:focus {
  outline: none;
  border-color: #007bff;
}
