/* Ensure full page height with center-aligned content */
.otp-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4; /* Subtle background */
  }
  
  /* Container for OTP input */
  .otp-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background-color: white;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    text-align: left; /* Changed text alignment to left */
  }
  
  /* Heading style */
  .reset-heading {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  /* Styling for input fields */
  .email {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .email-label {
    font-size: 16px;
    color: #555;
  }
  
  .email-input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  /* Position Send OTP button to the right end */
  .otp-actions {
    display: flex;
    justify-content: flex-end; /* Aligns the button to the right */
    width: 100%; /* Ensure the button is within the container */
  }
  

  .otp-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }
  
  .otp-label {
    font-size: 16px;
    color: #555;
  }
  
  .otp-boxes {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
  
  .otp-field {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    transition: border 0.3s ease-in-out;
  }
  
  .otp-field:focus {
    border-color: #3b8c3a; /* Green border on focus */
    outline: none;
  }
  
  /* Button styling */
  .sent-otp-button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color:transparent !important;
    color: #3b8c3a !important;
    border: none !important;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .verify-otp-button{
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #3b8c3a !important;
    color: white !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .sent-otp-button:hover{
    text-decoration: underline !important;
  }
  .verify-otp-button:hover {
    background-color: white !important; 
    color: #3b8c3a !important;
    border: none !important;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .otp-container {
      padding: 20px;
      width: 100%;
    }
  
    .email-input {
      max-width: 100%;
    }
  
    .otp-field {
      width: 40px;
      height: 40px;
    }
  }
  .reset-heading {
    color: #333;
    font-size: 24px;
    font-weight: 500;
    text-align: center; /* Center the text horizontally */
    margin-bottom: 20px;
  }
  