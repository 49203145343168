/* General Dashboard Styles */
body {
  font-family: "Roboto", Arial, sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
  color: #333;
}
.dashboard-visuals {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.dashboard-image-banner {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Dashboard Header */
.dashboard-header {
  text-align: center;
  margin: 20px 0;
}

.dashboard-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #007bff;
  letter-spacing: 1px;
}

/* Dashboard Cards */
.dashboard-first {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.column {
  background: linear-gradient(145deg, #ffffff, #f1f1f1);
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.column:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.dashboard-details {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 15px;
}

.data-value {
  font-size: 2rem;
  font-weight: 700;
  color: #4bc0c0;
}

/* Analytics Section */
.dashboard-analytics {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.date-picker-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.date-picker {
  font-size: 1rem;
  padding: 10px;
  width: 180px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.filter-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

/* Graph Container */
.graph-container {
  flex: 1;
  max-width: 600px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Custom Table */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.custom-table th {
  background: linear-gradient(145deg, #000000, #000812);
  color: white;
  text-align: left;
  padding: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.custom-table td {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  color: #333;
}

.custom-table tr:hover {
  background-color: #f1f9ff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
/* Booking List */
/* Booking List Section */
.booking-list {
  flex: 1;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.booking-header h4 {
  font-size: 1.5rem;
  color: #4bc0c0;
}

.booking-header .view-all {
  font-size: 0.9rem;
  text-decoration: none;
  color: #4bc0c0;
  transition: color 0.3s;
}

.booking-header .view-all:hover {
  color: #0056b3;
}

.booking-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 300px;
}

.booking-card {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f9f9ff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  gap: 15px;
}

.guest-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.guest-info {
  flex: 1;
}

.guest-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.guest-room {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.booking-details {
  text-align: right;
}

.booking-date {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

.booking-time {
  font-size: 0.9rem;
  color: #666;
}

/* Add scrollbar styling */
.booking-cards::-webkit-scrollbar {
  width: 6px;
}

.booking-cards::-webkit-scrollbar-thumb {
  background: #007bff;
  border-radius: 3px;
}

.booking-cards::-webkit-scrollbar-thumb:hover {
  background: #0056b3;
}
/* current booking */
.current-booking-section {
  margin-top: 40px;
}
.trash-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Change cursor to pointer */
  transition: all 0.3s ease-in-out; /* Smooth hover effect */
}

.trash-button:hover {
  background-color: #c82333; /* Slightly darker red on hover */
  transform: scale(1.1); /* Slightly increase size on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
}

.trash-button:active {
  transform: scale(0.95); /* Slightly shrink on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce shadow on click */
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.custom-table th {
  background-color: #f4f6f9;
  font-weight: bold;
}

.custom-table td {
  background-color: #fff;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.status-badge.booked {
  background-color: #28a745;
}

.status-badge.checkout {
  background-color: #6f42c1;
}

.status-badge.checkin {
  background-color: #007bff;
}

.status-badge.cancelled {
  background-color: #dc3545;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-first {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .dashboard-visuals {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .dashboard-first {
    grid-template-columns: 1fr;
  }
}
