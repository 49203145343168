.form-writing{
  width:100%;
  height:50px !important;
  border-radius: 50px !important;
}
.login-button{
  border-radius: 50px !important;
  background-color: green !important;
  border: none !important;
}
.login-page-image{
  width:100% !important;
  height:700px !important;
}
.login-headline{
  color:orange !important;
}
.login-forget-page{
  color:orange !important;
}
.body{
  color:antiquewhite
}