/* Center the form in the middle of the page */
.password-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  /* Style the form box */
  .password-form-box {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: 100%;
    max-width: 450px;
    border: 1px solid #ddd; /* Subtle border */
  }
  
  /* Style the form title */
  .form-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 600;
    color: #333;
  }
  
  /* Style the form labels */
  .password-label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-bottom: 8px;
  }
  
  /* Style the input fields */
  .form-input {
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
  .form-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
  
  /* Style the error message */
  .error-message {
    color: red;
    text-align: center;
    margin-top: 10px;
  }
  
  /* Style for the form buttons */
  .form-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px;
  }
  
  /* Save Button */
  .change-password-savebutton {
    width: 48%;
    border: none !important;
    background-color: #28a745 !important;
    color: white !important;
    font-weight: 500;
    padding: 12px;
    border-radius: 8px;
  }
  
  /* Cancel Button */
  .change-password-cancelbutton {
    width: 48%;
    border: none !important;
    color: #28a745 !important;
    background-color: white !important;
    font-weight: 500;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #28a745;
  }
  
  /* Button hover effects */
  .change-password-savebutton:hover {
    background-color: #218838;
  }
  
  .change-password-cancelbutton:hover {
    background-color: #e9f5e5;
  }
  