.testimonial-wrapper {
  position: relative;
  padding: 20px;
  background-color: #f9f9f9;
}

.add-testimonial-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-testimonial-button:hover {
  background-color: #0056b3;
}

.show-modal {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-testimonial-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-testimonial-form input,
.add-testimonial-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.add-testimonial-form button {
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.add-testimonial-form .close-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
}

.add-testimonial-form .close-button:hover {
  background-color: #c82333;
}

.add-testimonial-form button[type="submit"] {
  background-color: #28a745;
  color: #fff;
  border: none;
}

.add-testimonial-form button[type="submit"]:hover {
  background-color: #218838;
}

/* Testimonial List */
.testimonial-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 60px;
}

.testimonial-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 300px;
  padding: 20px;
  position: relative;
}

.testimonial-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.edit-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.edit-button:hover {
  color: #007bff;
}

.delete-button:hover {
  color: #dc3545;
}
.gallery-image {
  object-fit: contain;
  height: auto;
  width: 250px;
}
